/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import {StyledCell, StyledStatus, StyledTable} from "./styles";
import {LabelRegularProps} from "../../../components/Form/TextInput/styles";
import {getListCase, SERVICES} from "../../../services";
import { PAGINATION, CLAIM_STATUS_VALUE_MAP_TEXT, CLAIM_STATUS_COLOR, CLAIM_STATUS, FILTER_CASE_STATUS, APPROVED_RE_ISSUED_TEXT } from "../../../constants";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {setTotalCase} from "../../../store/appSlice";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {formatCurrency} from "helpers";
import Loading from "../../../components/Loading";
import { isEmpty } from "lodash";
import { SocketContext } from "components/SocketContext";

type Props = {
    keyword?: string,
    sortDesc?: string,
    sortAsc?: string,
    claimStatus?: string,
};

export default function HomeTable(props: any) {
    const [loading, setLoading] = useState(false);
    const {keyword, sortDesc, sortAsc, claimStatus} = props;
    const [list, setList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [keySearch, setKeySearch] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname, state } = useLocation();
    const caseIds = useMemo(() => new Set(list.map((it: any) => it.id)), [list]);
    const [socketData, setSocketData] = useState({} as any);

    useEffect(() => {
        if (socketData && !isEmpty(socketData)) {
            reload(socketData.action !== 'newCase' ? socketData.data : undefined);
        }
    }, [socketData])

    const reload = (updates?: { id: string }[]) => {
        if (!updates || updates.some(it => caseIds.has(it.id))
        ) {
            handleGetAllData(false, currentPage);
        }
    };

    const socket = useContext(SocketContext);

    useEffect(() => {
        socket.onmessage = (e: MessageEvent) => {
            const { action, data } = JSON.parse(e.data);

            switch (action) {
                case "newCase":
                    console.log({ action, data })
                    setSocketData({action});

                    break;
                case "updateCaseDoctor":
                case "updateCase":
                    const caseData = data?.map((item: { id: string, message: string, timestamp: number }) => {
                        let mappedItem = item.message.split(action === 'updateCaseDoctor' ? 'Update case doctor' : 'Update case')?.slice(1)[0];
                        return JSON.parse(mappedItem);
                    })
                    setSocketData({action, data: caseData });
                    break;
                default:
                    break;
            }
        };
    }, []);

    useEffect(() => {
        setKeySearch(keyword);
    }, [keyword])

    useEffect(() => {
        handleGetAllData(true, 1);
    }, [keyword, sortDesc, sortAsc, claimStatus, pathname])

    const handleCLickCase = (caseId: string, record: any) => {
        if (record?.claim_status === CLAIM_STATUS.PAC_INITIATED_PATIENT) return;
        if (record?.claim_status === CLAIM_STATUS.ASSESSOR_TRIGGERED_PAC) return;
        navigate(`/epac/session-a?case_id=${caseId}`);
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Case No',
            dataIndex: 'serial',
            key: 'serial',
            width: 150,
            fixed: 'left',
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Case
                        No</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Doctor name',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
            width: 150,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Doctor
                        name</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Diagnosis',
            dataIndex: 'diagnoses_name',
            key: 'diagnoses_name',
            width: 250,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps theme={{
                        color: '#727C8A',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                    }}>Diagnosis</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Total amount',
            key: 'total_amount',
            dataIndex: 'total_amount',
            width: 150,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Total
                        amount</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{formatCurrency(text) ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Patient name',
            key: 'patient_name',
            dataIndex: 'patient_name',
            width: 150,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Patient
                        name</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Admission date',
            key: 'date_of_admission',
            dataIndex: 'date_of_admission',
            width: 250,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>
                        Admission date
                    </LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Submission date',
            key: 'date_of_submission',
            dataIndex: 'date_of_submission',
            width: 250,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Submission
                        date</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'New Comment',
            key: 'new_comment',
            dataIndex: 'comment',
            width: 250,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>New
                        Comment</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Policy owner GE shield group',
            key: 'policy_owner',
            dataIndex: 'policy_owner',
            width: 250,
            render: (text: any, record: any) => (
                <StyledCell>
                    <LabelRegularProps
                        theme={{color: '#727C8A', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Policy owner
                        GE shield group</LabelRegularProps>
                    <LabelRegularProps theme={{
                        color: '#101820',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}>{text ?? '-'}</LabelRegularProps>
                </StyledCell>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'map_status',
            fixed: 'right',
            width: 330,
            render: (map_status: any) => (
                <StyledCell>
                    <LabelRegularProps theme={{
                        color: '#727C8A',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                    }}>Status</LabelRegularProps>
                    <StyledStatus theme={{
                        color: '#2AC3FF',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        background: '#E4F3FC'
                    }} className={CLAIM_STATUS_COLOR[map_status.status]}>{map_status.claim_status == CLAIM_STATUS.APPROVED && map_status.serial.indexOf('-0') >= 0 ? APPROVED_RE_ISSUED_TEXT : CLAIM_STATUS_VALUE_MAP_TEXT[map_status.status]}</StyledStatus>
                </StyledCell>
            ),
        },
    ];

    // declare the data fetching function
    const handleGetAllData = async (isHaveLoading = true, pageNumber?: number) => {
        setLoading(isHaveLoading);
        try {
            const { data } = await getListCase(
                keyword,
                claimStatus,
                sortDesc,
                sortAsc,
                PAGINATION.LIMIT_10,
                pageNumber ?? currentPage,
                (pathname === '/archive' ? FILTER_CASE_STATUS.ARCHIVE : FILTER_CASE_STATUS.AVAILABLE),
            );
            setList(handleData(data?.data?.items || []));
            dispatch(setTotalCase(data?.data?.page_info?.total || 0));
            setTotal(data?.data?.page_info?.total || 0);
            setCurrentPage(data?.data?.page_info?.page || 1)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    const handleData = (data: any[]) => {
        data.map(ele => {
            if(ele.claim_status === CLAIM_STATUS.CANCELLED && ele.cancel_reason === "Step Down") {
                ele.claim_status = "GE_TAKEOVER";
            }
            ele.map_status = {
                claim_status: ele.claim_status,
                serial: ele.serial,
                status: ele.claim_status
            }
            ele.status = CLAIM_STATUS_COLOR[ele.claim_status];
            ele.createdAt = ele?.createdAt ? moment(ele?.createdAt).format('DD-MMM-YYYY HH:mm:ss') : '-';
            ele.date_of_admission = ele?.date_of_admission ? moment(ele?.date_of_admission).format('DD-MMM-YYYY') : '-';

            if (ele?.diagnoses?.length) ele.diagnoses_name = (ele?.diagnoses[0]?.icd_10 + ' - ' + ele?.diagnoses[0]?.name);
            return ele;
        })
        return data;
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        handleGetAllData(true, pageNumber);
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <StyledTable
            rowClassName={'__styled__row'}
            onRow={(record: any, rowIndex) => {
                return {
                    onClick: event => {
                        handleCLickCase(record?.id, record);
                    }, // click row
                };
            }}
            columns={columns}
            scroll={{x: 1300}}
            dataSource={list}
            pagination={{
                defaultPageSize: PAGINATION.LIMIT_10,
                onChange: (page, pageSize) => { handlePageChange(page) },
                current: currentPage,
                total: total,
                showSizeChanger: false
            }}
            showHeader={false}
        />
    )
}
