import axios, {AxiosError} from "axios";
import {setUserInfo} from "../pages/auth/authSlice";
import {MAX_SIZE_UPLOAD, PAGINATION} from "../constants";
import {store} from "store";
import {DoctorTypes} from "../types/doctor.types";
import {InvitePatientRequestTypes} from "../types/epac.types";
import {Auth} from "aws-amplify";
import moment, { Moment } from "moment";
import _ from "lodash";
import { message } from "antd";

export const SERVICES: any = {
    CORE: process.env.REACT_APP_CORE_SERVICE_URL,
    PROVIDER: process.env.REACT_APP_PROVIDER_SERVICE_URL,
    PRODUCT: process.env.REACT_APP_PRODUCT_SERVICE_URL,
    DA: process.env.REACT_APP_DA_SERVICE_URL,
    COMMON: process.env.REACT_APP_COMMON_SERVICE_URL,
    WEBSOCKET: process.env.REACT_APP_WEBSOCKET_URL,
    S3: process.env.REACT_APP_S3_URL,
    PREAUTHORISATION: process.env.REACT_APP_ENABLE_PREAUTHORISATION_PAC,
};

const serviceCreator = (baseUrl: string, unAuthorization = false) => {
    const isProd = process.env.NODE_ENV !== "development";

    const service = axios.create({
        baseURL: baseUrl,
        timeout: 60000,
        timeoutErrorMessage: "Request Timeout",
    });

    service.interceptors.request.use(
        async function getOrRefreshToken(config: any) {
            if(config.url.includes('get-for-signup') || (config.method == 'post' && config.url == '/doctors'))
            {
                return config;
            }

            try {
                const session = await Auth.currentSession();
                const token = session.getAccessToken().getJwtToken();
                        
                store.dispatch(
                    setUserInfo({
                        idToken: session.getIdToken().getJwtToken(),
                        refreshToken: session.getRefreshToken().getToken(),
                        accessToken: token
                    })
                );

                if (token && !unAuthorization) {
                    config.headers.common["Authorization"] = `Bearer ${token}`;
                }
                return config;
            } catch (error) {
                store.dispatch(setUserInfo(null));
                return Promise.reject(error);
            }
        },
        function (error) {
            if (!isProd) {
                //console.log(error);
            }
            return Promise.reject(error);
        }
    );

    service.interceptors.response.use(
        function (response) {
            if (!isProd) {
                //console.log("SUC Resp: ", response.data);
            }

            return response;
        },
        function (error) {
            if (error.response) {
                if (error.response?.status === 401) {
                    store.dispatch(setUserInfo(null));
                }

                if (!isProd) {
                    //console.log("ERR Resp: ", error.response);
                }
                return {
                    status: error.response?.status || 400,
                    data: error.response?.data,
                };
            }

            if (!isProd) {
                //console.log("Err: ", error);
            }

            return Promise.reject(error);
        }
    );
    return service;
};

const coreService = serviceCreator(SERVICES.CORE);
export const providerService = serviceCreator(SERVICES.PROVIDER);
export const productService = serviceCreator(SERVICES.PRODUCT);
const daService = serviceCreator(SERVICES.DA);
const commonService = serviceCreator(SERVICES.COMMON);

let LAST_NEXT_FOCUS_ACTIVE = 0;
export const onNextFocus = (
    event: any,
    target: string,
    isClass?: boolean,
    callback?: any
) => {
    if (event.key === "Enter") {
        if (callback) {
            callback();
            return;
        }
        const element: any = window.document.querySelector(
            isClass ? `.${target}` : `#${target}`
        );
        if (element && LAST_NEXT_FOCUS_ACTIVE + 200 < Date.now()) {
            element.focus();
            LAST_NEXT_FOCUS_ACTIVE = Date.now();
        }
    }
};

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getDoctorDetail = (id: string) => {
    return providerService.get(`/doctors/${id}`);
};

export const putDoctorDetail = (id: string, data: any) => {
    return providerService.put(`/doctors/${id}`, data);
};

export const putDoctorFirstTime = (id: string, data: any) => {
    return providerService.put(`/doctors/${id}/first-time`, data);
};

export const postSignUpDoctor = (doctor: DoctorTypes) => {
    return providerService.post(`/doctors`, doctor);
};

export const getCommentsOfForm = (form_id: string) => {
    return providerService.get(`pac/${form_id}/comments?mode=doctor`);
};

export const sendComment = (data: any) =>
    providerService.post(`/pac/comments`, data);

export const  sendDocumentComment =  (form_id:string,comment_id:string,data: any) => providerService.put(`/pac/${form_id}/comments/${comment_id}/update-document`, data)
export const  deleteDocumentComment =  (form_id:string,comment_id:string,document_id: string) => providerService.delete(`/pac/${form_id}/comments/${comment_id}/delete-document/${document_id}`)

export const deleCommentOfField = (form_id: string, data: any) => {
    return providerService.post(`/pac/${form_id}/delete-comments`, data).catch(reason => null);
}

export const postLoginDoctor = async (doctor: DoctorTypes) => {
    const result = await Auth.signIn(doctor.email, doctor.password).catch(
        (reason: any) => {
            return {
                error: true,
                message: reason.message,
            };
        }
    );
    if (result.error) {
        const resultData: any = {
            data: {
                message: result.message,
            },
            status: 400,
        };
        return resultData;
    }
    const resultData: any = {
        data: {
            attributes: result.attributes,
            AuthenticationResult: {
                AccessToken: result.signInUserSession.accessToken.jwtToken,
                IdToken: result.signInUserSession.idToken.jwtToken,
                RefreshToken: result.signInUserSession.refreshToken.token,
                ExpiresIn: result.signInUserSession.accessToken.payload.exp,
            },
        },
        status: 200,
    };
    return resultData;
    //return providerService.post(`/doctor/login`, doctor);
};

export const getProfileDoctor = () => {
    return providerService.get(`/doctors/profile`);
};

export const getProfileDoctorForSignUp = (id: any) => {
    return providerService.get(`/doctors/get-for-signup/${id}`);
};

export const postInvitePatient = (invite: InvitePatientRequestTypes) => {
    return productService.post(`/invite-patients`, invite);
};

export const postValidatePatient = (invite: InvitePatientRequestTypes) => {
    return productService
        .post(`/validate-patient`, invite)
        .then((response) => response.data);
};

export const getSessionA = (caseId: string) => {
    return providerService.get(`/pac/formA/${caseId}`);
};
export const putSessionA = (caseId: string, data: any, isSubmit=true) => {
    if(isSubmit) {
        return providerService.put(`/pac/formA/${caseId}`, data);
    }else {
        return providerService.put(`/pac/formA/${caseId}?mode=save`, data);
    }
};

export const getSessionC = (caseId: string) => {
    return providerService.get(`/pac/formC/${caseId}`);
};
export const putSessionC = (caseId: string, data: any, isSubmit = true) => {
    if(isSubmit) {
        return providerService.put(`/pac/formC/${caseId}`, data);
    }else {
        return providerService.put(`/pac/formC/${caseId}?mode=save`, data);
    }
};

export const postUploadSignature = async (file: File | Blob, id: string) => {
    const maxSizeMsg = "File size for upload must not exceed " + MAX_SIZE_UPLOAD + "MB";
    if (file.size > MAX_SIZE_UPLOAD * 1024 * 1024) {
        message.error(maxSizeMsg);
        return "";
    }
    
    const formdata = new FormData();
    // @ts-ignore
    formdata.append("files", file, file?.name || `${Date.now()}_app_sign.png`);
    const {data, status} = await commonService.post(
        `/upload?folder=${id}`,
        formdata
    );
    if (status === 200) {
        return data?.data[0] || "";
    }
    return "";
  };
  

export const getSessionB = (caseId: string) => {
    return providerService.get(`/pac/formB/${caseId}`);
};
export const putPACFormB = (body: any, isSubmit = true) => {
    if(isSubmit) {
        return providerService.put(`/pac/formB/${body.case_id}`, body);
    }else {
        return providerService.put(`/pac/formB/${body.case_id}?mode=save`, body);
    }
};

export const getDoctorProviderCase = (
    limit: number = PAGINATION.LIMIT_100,
    page: number | 1 = 1
) => {
    return productService.get("/cases", {
        params: {limit, page},
    });
};

export const getListCase = (
    name: string,
    claim_status: string,
    sortDesc: string,
    sortAsc: string,
    limit: number = 10,
    page: number,
    status?: string,
) => productService.get("/cases", {
        params: {
            ...(!!name && { name: name?.trim() }),
            ...(!!claim_status && { claim_status: claim_status?.trim() }),
            ...(!!sortDesc && { sortDesc: sortDesc?.trim() }),
            ...(!!sortAsc && { sortAsc: sortAsc?.trim() }),
            limit,
            page,
            ...(!!status && { status: status?.trim() }),
        },
});

export const getListAnnouncement = (
    search: string,
    limit: number = 10,
    page: number
) => providerService.get("/announcements", {
        params: {
            ...(!!search && { search: search?.trim() }),
            limit,
            page
        },
});

export const getDoctorCase = (caseId: string) => {
    return providerService.get(`/pac/doctorCase/${caseId}`);
};

export const getProviderFeed = (
    limit: number = PAGINATION.LIMIT_6,
    page: number | 1 = 1
) => {
    return providerService.get("/feed", {
        params: {limit, page},
    });
};

export const updateProviderFeed = (body: any) => {
    return providerService.put(`/feed/${body.feed_id}`, body);
};

export const updateProviderAnnouncement = (body: any) => {
    return providerService.put(`/announcements/${body.announcement_id}`, body);
};

export const getHolidays = () => commonService
    .get<{ date: string }[]>(`/holidays`)
    .then(it => it.data.map(_ => _.date));

export default daService;

export const getNext4WorkingDays = (submissionDate: any, listHolidays: any = []) => {
    // B2B-3758 : submission date after 5pm is considered next working day
    let numberOfWorkingDays = submissionDate.hour() >= 17 || isNonWorkingDay(submissionDate, listHolidays) ? 5 : 4;
    return getNextWorkingDays(submissionDate, listHolidays, numberOfWorkingDays);
}

export const getNextWorkingDays = (submissionDate: any, listHolidays: any = [], numberOfDay: number) => {
    let orginalCurrentTime = submissionDate;
    let date;
    let resultDate;
    let count = 0;
    date = _.cloneDeep(orginalCurrentTime);
    resultDate = _.cloneDeep(orginalCurrentTime);
    while (count < numberOfDay) {
        // Increment the date by 1 day
        date = date.add(1, "days");
        // Check if the date is a weekend day (Saturday or Sunday) or the date is a holiday
        if (isNonWorkingDay(date, listHolidays)) {
            continue;
        }

        resultDate = _.cloneDeep(date);
        count++;
    }

    return resultDate;
}

const isNonWorkingDay = (currentDate: any,listHolidays: any = []) => {
    let _currentDateString = currentDate.format("YYYY-MM-DD");
    return currentDate.weekday() === 0 || currentDate.weekday() === 6 || listHolidays.some((h: string) => h == _currentDateString);
}

export const checkDisabledDateAdmission = (currentDate: any, listHolidays: any = []) => {
    // Disable if current date is
    // 1. Over 2 months from now
    // 2. Less than 4 working days from now
    return currentDate.isAfter(moment().add(2, "M")) 
    || currentDate.format('YYYY-MM-DD') < getNext4WorkingDays(moment(), listHolidays).format('YYYY-MM-DD');
}
